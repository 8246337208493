import { Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterModule } from '@angular/router';
import { CheckBlockService } from '../../../check-block/check-block.service';
import { ICheckBlock } from '../../../check-block/interface/check-block.interface';
import { ISample } from '../../../samples/interface/sample.interface';
import { SamplesService } from '../../../samples/service/samples.service';
import { ICheckBlockRaisedUserNotification } from '../../notification.type';
import { NOTIFICATION_INPUT } from '../../notifications.type';
import { NotificationsService } from '../../notifications.service';

@Component({
  standalone: true,
  imports: [RouterModule, MatButtonModule],
  template: `
    <h3>Checkblock has new message</h3>

    <a
      mat-stroked-button
      [routerLink]="['/tows', sample.towId, 'samples', sample._id]"
      [queryParams]="{ checkblock: checkBlock._id, taxon: checkBlock.taxonId }"
      queryParamsHandling="merge"
      (click)="notificationService.setStatus(notification._id, 'read')"
    >
      {{ sample.oldSampleId }}
    </a>

    <p>{{ checkBlock.messages[0].message }}</p>
  `,
})
export class CheckBlockMessageReplyNotificationComponent {
  checkBlock: ICheckBlock;
  sample: ISample;

  constructor(
    @Inject(NOTIFICATION_INPUT) readonly notification: ICheckBlockRaisedUserNotification,
    readonly notificationService: NotificationsService,
    checkBlocks: CheckBlockService,
    samples: SamplesService,
  ) {
    checkBlocks.get(this.notification.metadata.checkBlockId).then((checkBlock) => {
      this.checkBlock = checkBlock;
      samples.getOne('', checkBlock.sampleId).then((sample) => (this.sample = sample));
    });
  }
}
