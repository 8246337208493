<div class="notification-container" [class.unread]="notification.status === 'unread'">
  <div style="flex-grow: 1">
    <span style="color: #929292">{{ notification.createdAt | date: 'medium' : 'UTC' }}</span>
    <ng-container *ngComponentOutlet="component; injector: childInjector" />
  </div>
  <button mat-flat-button (click)="setStatus('archived')">
    <mat-icon>archive</mat-icon>
    Archive
  </button>
  @switch (notification.status) {
    @case ('read') {
      <button mat-flat-button (click)="setStatus('unread')">
        <mat-icon>mark_email_unread</mat-icon>
        Mark as unread
      </button>
    }
    @case ('unread') {
      <button mat-flat-button (click)="setStatus('read')">
        <mat-icon>mark_email_read</mat-icon>
        Mark as read
      </button>
    }
  }
</div>
