<app-header>
  <p>{{ notificationsService.unreadCount() }} Unread notifications.</p>
  <button mat-flat-button (click)="reload()">
    <mat-icon>refresh</mat-icon>
    Refresh
  </button>
  @if (notificationsService.unreadCount()) {
    <button mat-flat-button (click)="markAllRead()">
      <mat-icon>mark_email_read</mat-icon>
      Mark all as read
    </button>
  }
</app-header>

@if (notifications.length) {
  <mat-card class="margin">
    <mat-card-content>
      @for (notification of notifications; track notification.id) {
        <app-notifications-notification
          [notification]="notification"
          (refreshNotifier)="reload()"
        />
      }
    </mat-card-content>
  </mat-card>
}

@if (hasMore) {
  <button mat-button (click)="showMore()" class="margin" style="width: 100%">Show 10 more</button>
}
