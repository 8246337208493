import { Component } from '@angular/core';
import { NotificationsService } from '../../notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications-screen.component.html',
  styleUrl: './notifications-screen.component.scss',
})
export class NotificationsScreenComponent {
  data: any = {};
  notifications = [];
  page = 0;
  hasMore: boolean;

  constructor(readonly notificationsService: NotificationsService) {
    this.loadNotifications();
  }

  reload() {
    this.page = 0;
    this.notifications = [];
    this.loadNotifications();
    this.notificationsService.refreshUnreadCount();
  }

  showMore() {
    this.page++;
    this.loadNotifications();
  }

  async loadNotifications() {
    const data = await this.notificationsService.getNotifications(this.page);
    this.notifications = this.notifications.concat(data.notifications);
    this.hasMore = data.hasMore;
  }

  async markAllRead() {
    await this.notificationsService.markAllRead();
    await this.notificationsService.refreshUnreadCount();
    await this.loadNotifications();
  }
}
